import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeKey = 'color-theme';
  public themeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.initializeTheme();
  }

  initializeTheme() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const savedTheme = localStorage.getItem(this.themeKey);
    const theme = savedTheme ? savedTheme : (prefersDark ? 'dark' : 'light');
    this.setTheme(theme);
  }

  toggleTheme() {
    const currentTheme = this.isDarkMode() ? 'dark' : 'light';
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    this.setTheme(newTheme);
  }

  private setTheme(theme: string) {
    
    if(theme=="light"){
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');

    }
    if(theme=="dark"){
      document.documentElement.classList.remove('light');
      document.documentElement.classList.add('dark');
    }
    localStorage.setItem(this.themeKey, theme);
    this.themeChange.emit(theme); 
  }

  isDarkMode(): boolean {
    return document.documentElement.classList.contains('dark');
  }
}
